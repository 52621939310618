<template>
    <div class="card bs-5 mb-5 container">
        <div class="fl-te-c pb-3">
            <h4 v-html="'IMPACT STORIES'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.impactStoriesAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn icon="fa fa-eye" text="View" color="info" size="xs"
                         @click="$router.push({path: '/impact-stories/' + rowData.id + '/' + rowData.name + '/link/'})" />
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Impact Stories" ref="impactStoriesAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddImpactStories @success="formSuccess"></AddImpactStories>
        </modal>

        <modal title="Edit Impact Stories" ref="impactStoriesEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditImpactStories :initial-data="editingItem" @success="formSuccess"></EditImpactStories>
        </modal>

        <delete-modal ref="impactStoriesDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Impact Stories <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import AddImpactStories from './AddImpactStories';
import EditImpactStories from './EditImpactStories';

export default {
    name       : 'ImpactStories',
    components : { AddImpactStories, EditImpactStories },
    data () {
        return {
            listUrl      : urls.impactStories.list,
            deleteUrl    : urls.impactStories.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'name',
                    sortField  : 'name',
                    title      : 'Name',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.impactStoriesAddModal.close();
            refs.impactStoriesEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.impactStoriesEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.impactStoriesDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Impact Stories..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.impactStoriesDeleteModal.close();
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
