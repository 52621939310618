import { render, staticRenderFns } from "./EditImpactStories.vue?vue&type=template&id=7054f666&scoped=true&"
import script from "./EditImpactStories.vue?vue&type=script&lang=js&"
export * from "./EditImpactStories.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7054f666",
  null
  
)

export default component.exports