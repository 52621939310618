import { render, staticRenderFns } from "./ImpactStories.vue?vue&type=template&id=7696b63e&scoped=true&"
import script from "./ImpactStories.vue?vue&type=script&lang=js&"
export * from "./ImpactStories.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7696b63e",
  null
  
)

export default component.exports